<template>
  <div>
    <button @click.prevent="openModal"
            type="button" class="btn btn-primary">
      <i class="fi-plus me-2"></i>
      Invite User
    </button>

    <vue-final-modal v-model="visible">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">
            <h5 class="modal-title">
              Invite User
            </h5>
            <button type="button" class="btn-close" aria-label="Close" @click="closeModal"></button>
          </div>
          <div class="modal-body">

            <!-- Name -->
            <div>
              <label class="form-label" for="name">Name</label>
              <input ref="nameInput"
                     v-model="invite.name" type="text" class="form-control" id="name"/>
            </div>
            <!-- / Name -->

            <!-- Email -->
            <div class="mt-4">
              <label class="form-label" for="email">Email</label>
              <input v-model="invite.email" type="email" class="form-control" id="email"/>
            </div>
            <!-- / Email -->

            <!-- Is Admin -->
            <div class="mt-4">
              <div class="form-check">
                <input :value="true"
                       v-model="invite.is_admin"
                       class="form-check-input"
                       id="isAdminCheckbox"
                       type="checkbox">
                <label class="form-check-label" for="isAdminCheckbox">Is Admin?</label>
              </div>
            </div>
            <!-- / Is Admin -->

            <!-- Actions -->
            <div class="mt-4">
              <a class="btn btn-primary btn-lg rounded-pill" href="#"
                 :class="{'disabled': !canSave, 'opacity-50': isSaving}"
                 @click.prevent="sendInvite">
                <spinner v-if="isSaving" class="spinner-border-sm me-2"></spinner>
                {{ isSaving ? 'Sending Invite' : 'Send Invite' }}
              </a>
            </div>
            <!-- / Actions -->

          </div>
        </div>
      </div>
    </vue-final-modal>
  </div>
</template>

<script>
import companyInvites from "../../../api/company-invites";
import {useToast} from 'vue-toastification';
import Spinner from "../../../components/Spinner";
import Forms from "../../../mixins/Forms";

const toast = useToast();

export default {
  name: "InviteCompanyUserButtonAndModal",
  components: {Spinner},
  mixins: [Forms],
  data() {
    return {
      visible: false,
      isSaving: false,
      serverErrors: [],
      invite: {
        name: '',
        email: '',
        is_admin: false
      }
    }
  },
  computed: {
    canSave() {
      return (
          (this.invite.name && this.invite.name.trim())
          && (this.invite.email && this.invite.email.trim())
      );
    }
  },
  methods: {
    openModal() {
      this.visible = true;
      let vm = this;
      window.setTimeout(function () {
        vm.$refs.nameInput.focus();
      }, 230);
    },

    closeModal() {
      this.visible = false;
    },

    sendInvite() {
      if (!this.canSave) {
        return false;
      }

      let vm = this;
      vm.isSaving = true;
      vm.serverErrors = [];
      companyInvites.sendInvite(this.invite).then(r => {
        vm.isSaving = false;
        vm.invite = {
          name: '',
          email: '',
          is_admin: false
        };
        toast.success('Invite sent successfully');
        vm.closeModal();
        vm.$emit('invite-sent');
      }).catch(e => {
        console.log(e);
        this.setAndNotifyErrors(e);
        vm.isSaving = false;
      });
    }
  }
}
</script>

<style scoped>

</style>