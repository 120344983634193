<template>
  <div>
    <!-- Page Header -->
    <section class="bg-dark pt-5 pb-4">
      <div class="container py-5">
        <h1 class="text-light pt-1 pt-md-3 mb-4">All Users</h1>
      </div>
    </section>
    <!-- / Page Header -->

    <!-- Results -->
    <section class="position-relative bg-white rounded-xxl-4 zindex-5" style="margin-top: -30px;">
      <div class="container pt-4 pb-5 mb-md-4">
        <!-- Breadcrumb-->
        <breadcrumb :crumbs="crumbs" class="pb-4 my-2"></breadcrumb>

        <!-- Results grid -->
        <div class="row">
          <div class="col-md-3">
            <sidebar-filters :filters="filters"></sidebar-filters>
          </div>
          <div class="col-md-9">
            <!-- Is Loading -->
            <div v-if="isLoading" class="text-center">
              <spinner></spinner>
            </div>
            <!-- / Is Loading -->

            <!-- No results -->
            <no-results v-if="!isLoading && userRoles.length === 0 && invites.length === 0 && (!this.filters.status)">
              No users to show
            </no-results>
            <!-- / No results -->

            <!-- Active users: no results -->
            <no-results v-if="!isLoading && userRoles.length === 0 && shouldShowUserRoles && !shouldShowInvites">
              No users to show
            </no-results>
            <!-- / Active users: no results -->

            <!-- Invited users: no results -->
            <no-results v-if="!isLoading && invites.length === 0 && shouldShowInvites && !shouldShowUserRoles">
              <!--              No invites to show-->
              No stragglers here, all your invites have been accepted
            </no-results>
            <!-- / Invited users: no results -->

            <!-- Results wrapper -->
            <div v-if="!isLoading && (userRoles.length || invites.length)">

              <!-- User roles -->
              <div v-if="shouldShowUserRoles">
                <user-role-card v-for="(userRole, i) in userRolesToShow"
                                :user-role="userRole"
                                :key="i"
                                class="mb-3"></user-role-card>
              </div>
              <!-- / User roles -->

              <!-- Invites -->
              <div v-if="shouldShowInvites">
                <company-invite-card v-for="(invite, i) in invites"
                                     :invite="invite"
                                     :key="i"
                                     class="mb-3">
                </company-invite-card>
              </div>
              <!-- / Invites -->

              <!-- Invite modal -->
              <div class="mt-3">
                <invite-company-user-button-and-modal></invite-company-user-button-and-modal>
              </div>
              <!-- / Invite modal -->

            </div>
            <!-- / Results wrapper -->

          </div>
        </div>
        <!-- / Results grid -->
      </div>
    </section>
    <!-- / Results -->
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import Breadcrumb from "../../components/Breadcrumb";
import Spinner from "../../components/Spinner";
import NoResults from "../../components/NoResults";
import Forms from "../../mixins/Forms";
import SidebarFilters from "./Index/SidebarFilters";
import UserRoleCard from "./Index/UserRoleCard";
import CompanyInviteCard from "./Index/CompanyInviteCard";
import InviteCompanyUserButtonAndModal from "./Index/InviteCompanyUserButtonAndModal";

export default {
  name: "Index",
  mixins: [Forms],
  components: {
    InviteCompanyUserButtonAndModal,
    CompanyInviteCard,
    UserRoleCard,
    SidebarFilters,
    Breadcrumb,
    Spinner,
    NoResults
  },
  data() {
    return {
      crumbs: [
        {
          href: "/",
          label: "Home",
        },
        {
          href: "#",
          label: "Users",
          active: true,
        },
      ],
      filters: {}
    }
  },
  computed: {
    ...mapGetters('users', {
      userRoles: 'userRoles',
      invites: 'invites',
      isLoading: 'isLoading'
    }),

    userRolesToShow() {
      if (this.filters.status && this.filters.status === 'admin-users') {
        return _.filter(this.userRoles, ur => {
          return ur.is_admin;
        });
      }
      return this.userRoles;
    },

    shouldShowInvites() {
      if (this.filters && this.filters.status) {
        return this.filters.status === 'invited';
      }
      return true;
    },

    shouldShowUserRoles() {
      if (this.filters && this.filters.status) {
        return (this.filters.status === 'active-users' || this.filters.status === 'admin-users');
      }
      return true;
    }
  },
  methods: {
    ...mapActions('users', {
      launch: 'launch'
    }),

    // reload() {
    //   this.launch();
    // }
  }
}
</script>

<style scoped>

</style>