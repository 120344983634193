<template>
  <div class="card company-invite-card bg-secondary rounded-3 p-4 card-hover"
       @click.prevent="$emit('selected', invite)">
    <div class="left">
<!--      <img class="d-none d-sm-block rounded-circle" :src="getAvatarSrc({})" width="80" :alt="invite.name">-->
      <user-role-avatar class="d-none d-sm-block rounded-circle" :user-role="{}" width="80"></user-role-avatar>
    </div>
    <div class="middle">
      <b>{{ invite.name }}</b>
      <p class="mt-1 mb-0 pb-1 small">
        {{ invite.email }}
      </p>
    </div>
    <div class="right">
      <span class="badge bg-dark">Invited</span>
      <span v-if="invite.is_admin" class="badge bg-info ms-2">Admin</span>
    </div>
  </div>
</template>

<script>
import Images from "../../../mixins/Images";
import UserRoleAvatar from "../../../components/UserRoleAvatar";

export default {
  name: "CompanyInviteCard",
  components: {UserRoleAvatar},
  props: ['invite'],
  mixins: [Images],
  computed: {}
}
</script>

<style scoped lang="scss">
.company-invite-card:hover {
  cursor: pointer;
}

.company-invite-card {
  display: flex;
  flex-direction: row;

  .left {
    width: 100px;
    min-width: 100px;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .middle {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .right {
    flex-shrink: 1;
    align-items: center;
    display: flex;
    //flex-direction: column;
    //justify-content: center;
  }
}
</style>